<template>
<div class="kt-form kt-form--label-right">
    <div class="kt-portlet__body">
        <OneTimePassword />
    </div>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import OneTimePassword from '../../components/OneTimePassword/OneTimePassword.vue';

export default {
    name: 'AccountLocked',
    components: {
        OneTimePassword,
    },
    data() {
        return {};
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            isSuperAdmin: (state) => state.user.isSuperAdmin,
            userPermissions: (state) => state.user.userPermissions,
        }),
        canAccessBilling() {
            return this.isSuperAdmin || this.userPermissions.canManagePurchaseOrders || this.isSchoolAdmin;
        },
        valid() {
            const regex = /(WO)+\d*$/;
            return regex.test(this.purchaseOrderNumber);
        },
        isSchoolAdmin() {
            return this.user.school.role === 'School Admin';
        },
    },
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kt-form kt-form--label-right" }, [
    _c("div", { staticClass: "kt-portlet__body" }, [_c("OneTimePassword")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }